<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>查看明细</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="search__container">
      <a-form class="advanced-search-form" :form="form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="交易单号">
              <a-input v-decorator="['orderId']" />
            </a-form-item>
          </a-col>
          <!--<a-col :span="7">-->
          <!--<a-form-item label="付款/收款方">-->
          <!--<a-input v-decorator="['payeeUserId']" />-->
          <!--</a-form-item>-->
          <!--</a-col>-->
          <a-col :span="8">
            <a-form-item label="交易时间">
              <a-range-picker v-decorator="['time']" @change="onChangeTime" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="交易类型">
              <a-select v-decorator="['dealType']" :options="dealTypeOption" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否到账">
              <a-radio-group v-model="isToAccount">
                <a-radio value="3">已到账</a-radio>
                <a-radio value="1">未到账</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item>
              <a-space>
                <a-button type="primary" @click="search">搜索</a-button>
                <a-button @click="onReset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="table__container">
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :loading="loading"
        :pagination="pagination"
        :row-key="
          (record, index) => {
            return index;
          }
        "
        @change="onPage"
      >
        <!--交易类型 -->
        <template slot="dealType" slot-scope="text, record">
          {{ transDealType(text, record) }}
        </template>
        <!--是否到账-->
        <template slot="orderStatus" slot-scope="text">
          {{ text === "3" ? "已到账" : "未到账" }}
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
import { getOrderList } from "@/services/PayService";

const tblColumns = [
  {
    title: "交易单号",
    dataIndex: "orderId",
    width: ""
  },
  {
    title: "付款/收款方",
    dataIndex: "payeeUserIdInfo",
    width: ""
  },
  {
    title: "交易类型",
    dataIndex: "dealType",
    scopedSlots: { customRender: "dealType" },
    width: ""
  },
  {
    title: "实付款（元）",
    dataIndex: "realTransAmt", // 实付金额
    width: ""
  },
  {
    title: "到账金额（元）",
    dataIndex: "transAmt", //订单金额
    width: ""
  },
  // {
  //   title: "余额（元）",
  //   dataIndex: "feeAmt", // 接口缺字段
  //   width: ""
  // },
  {
    title: "是否到账",
    dataIndex: "orderStatus",
    scopedSlots: { customRender: "orderStatus" },
    width: ""
  },
  {
    title: "交易时间",
    dataIndex: "createTime",
    width: ""
  }
];

export default {
  name: "AmountDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      form: this.$form.createForm(this),
      tblData: [], // 表格数据
      tblColumns,
      loading: false,
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 10
      },
      allTblData: [], // 所有的表格数据
      dealTypeOption: [
        { value: "", label: "全部" },
        { value: "recharge", label: "充值" },
        { value: "withdrawal", label: "提现" },
        { value: "dealPay", label: "余额支付" },
        { value: "dealIncome", label: "余额收款" }
      ],
      startTime: "", // 交易起始时间
      endTime: "", // 交易结束时间
      isToAccount: "" // 是否到账
    };
  },
  mounted() {
    //获取订单列表
    this.getOrderList();
  },
  methods: {
    // 请求订单列表数据
    async getOrderList(
      searchObj = {
        orderId: "",
        startTime: "",
        endTime: "",
        // payeeUserId: "",
        dealType: "",
        orderStatus: "",
        isMch: ""
      }
    ) {
      const { data } = await getOrderList({
        ...searchObj,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.current,
        corporationId: localStorage.getItem("corporationId")
      });
      this.tblData = data.data.records || [];
      this.tblData.forEach(item => {
        let mobile = "";
        if (item.mobile) {
          mobile =
            "-" + item.mobile.substr(0, 3) + "****" + item.mobile.substr(7);
        }
        item.payeeUserIdInfo = item.realName + mobile;
      });
      this.pagination.total = data.data.total;
      this.pagination.showTotal = function(total, range) {
        return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
      };
    },
    // 分页 页码/pageSize 改变触发
    onPage(page) {
      this.pagination.current = page.current;
      this.getOrderList();
    },
    // 表单数据搜索
    search() {
      this.pagination.current = 1;
      const formData = this.form.getFieldsValue();
      const searchObj = {
        orderId: formData.orderId || "",
        startTime: this.startTime || "",
        endTime: this.endTime || "",
        // payeeUserId: formData.payeeUserId || "",
        orderStatus: this.isToAccount || ""
      };
      // 当交易类型是余额支付/余额收款时
      if (formData.dealType === "dealPay") {
        searchObj.dealType = "deal";
        searchObj.isMch = "0";
      } else if (formData.dealType === "dealIncome") {
        searchObj.dealType = "deal";
        searchObj.isMch = "1";
      } else {
        searchObj.dealType = formData.dealType || "";
        searchObj.isMch = "";
      }
      this.getOrderList(searchObj);
    },
    //重置表单
    onReset() {
      this.form.resetFields();
      this.isToAccount = null;
      this.startTime = "";
      this.endTime = "";
      this.getOrderList();
    },
    // 选择交易时间触发
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0] + " 00:00:00";
        this.endTime = dateString[1] + " 00:00:00";
      }
    },
    // 转换交易类型
    transDealType(type, record) {
      if (type === "recharge") {
        return "充值";
      } else if (type === "withdrawal") {
        return "提现";
      } else if (type === "deal") {
        if (record.isMch === "1") {
          return "余额收款";
        } else if (record.isMch === "0") {
          return "余额支付";
        }
      }
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  /*min-width: 8.85rem;*/
  text-align: left;
}

/deep/ .ant-form-item {
  margin-right: 4rem;
}
</style>
